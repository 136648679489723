* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}

input,
input:focus,
input:active,
input::placeholder,
select,
select:active,
select:focus,
textarea,
textarea:active,
textarea:focus {
  font-size: 16px !important;
}

.fancybox__content {
  padding: 0;
}

.refresh-error {
  height: 100vh;
}

.refresh-container .lds-ellipsis div {
  background: #0094f4;
}

.refresh-container .ptr__children {
  overflow: hidden !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  width: 15px;
}

.MuiFormHelperText-sizeSmall.MuiFormHelperText-root {
  margin-top: 0;
  line-height: 1.3;
}

.MuiPickersToolbar-root {
  grid-area: 1 / 2 / auto / 4;
}

@media print {
  body {
    zoom: 0.75;
  }

  .Toastify {
    display: none;
  }
}
